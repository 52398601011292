import {
  FieldComponent,
  HelpField,
  TextAreaField,
  InputField,
  SelectField,
  FieldsetComponent,
  Component,
  SectionComponent,
  FormComponent,
  NumberInputField,
  StringInputField,
  EmailInputField,
  DateInputField,
  StringSelectField,
  NumberSelectField,
  PaymentDetailsField,
  DateSelectField,
  SelectOptionDbField,
  RadioSelectField,
  StepCounterSelectField,
  CheckboxGroupField,
  CheckboxGroupDbField,
  ActionCTAField,
  SummaryField,
  StringSummaryField,
  CheckboxField,
  AddressField,
  ReviewDetailsField,
  AddProductCardListField,
  CatSummaryField,
  CatSummaryBField,
  QuickCheckoutField,
  AddProductField,
  ComponentSetComponent,
  UpdateFreshField,
} from "@forms/schema";
import dayjs from "dayjs";

export function isComponent(o: unknown): o is Component {
  return o !== null && typeof o === "object" && "component" in o && "_uid" in o;
}

export function isFieldset(o: Component): o is FieldsetComponent {
  return o.component === "fieldset";
}

export function isComponentSet(o: Component): o is ComponentSetComponent {
  return o.component === "component_set";
}

export function isSection(o: Component): o is SectionComponent {
  return o.component === "section";
}

export function isForm(o: Component): o is FormComponent {
  return o.component === "form";
}

export function instanceOfHelpField(field: FieldComponent): field is HelpField {
  return field.component === "help";
}

export function instanceOfTextAreaField(field: FieldComponent): field is TextAreaField {
  return field.component === "text_area";
}

export function instanceOfCheckboxField(field: FieldComponent): field is CheckboxField {
  return field.component === "checkbox";
}

export function instanceOfInputField(field: FieldComponent): field is InputField {
  return field.component === "input";
}

export function instanceOfSelectField(field: FieldComponent): field is SelectField {
  return field.component === "select";
}

export function instanceOfRadioSelectField(field: FieldComponent): field is RadioSelectField {
  return field.component === "radio_select";
}

export function instanceOfCheckboxGroupField(field: FieldComponent): field is CheckboxGroupField {
  return field.component === "checkbox_group";
}

export function instanceOfCheckboxGroupDbField(field: FieldComponent): field is CheckboxGroupDbField {
  return field.component === "checkbox_group_db";
}

export function instanceOfStepCounterSelectField(field: FieldComponent): field is StepCounterSelectField {
  return field.component === "step_counter_select";
}

export function instanceOfSelectOptionDbField(field: FieldComponent): field is SelectOptionDbField {
  return field.component === "select_option_db";
}

export function instanceOfUpdateFreshField(field: FieldComponent): field is UpdateFreshField {
  return field.component === "update_fresh_field";
}

export function instanceOfCtaActionField(field: FieldComponent): field is ActionCTAField {
  return field.component === "forms_CTA";
}

export function instanceOfPaymentDetailsField(field: FieldComponent): field is PaymentDetailsField {
  return field.component === "payment_details";
}

export function instanceOfQuickCheckoutField(field: FieldComponent): field is QuickCheckoutField {
  return field.component === "quick_checkout";
}

export function instanceOfCatSummaryField(field: FieldComponent): field is CatSummaryField {
  return field.component === "cat_summary";
}
export function instanceOfCatSummaryBField(field: FieldComponent): field is CatSummaryBField {
  return field.component === "cat_summary_b";
}

export function instanceOfReviewDetailsField(field: FieldComponent): field is ReviewDetailsField {
  return field.component === "review_details";
}

export function instanceOfSummary(field: FieldComponent): field is SummaryField {
  return field.component === "summary";
}

export function instanceOfAddress(field: FieldComponent): field is AddressField {
  return field.component === "address";
}

export function instanceOfAddProductCardList(field: FieldComponent): field is AddProductCardListField {
  return field.component === "form_add_product_card_list";
}

export function instanceOfAddProduct(field: FieldComponent): field is AddProductField {
  return field.component === "form_add_product";
}

export function instanceOfNumberInputField(inputField: InputField): inputField is NumberInputField {
  return inputField.type === "number";
}

export function instanceOfStringInputField(inputField: InputField): inputField is StringInputField {
  return inputField.type === "text";
}

export function instanceOfEmailInputField(inputField: InputField): inputField is EmailInputField {
  return inputField.type === "email";
}

export function instanceOfDateInputField(inputField: InputField): inputField is DateInputField {
  return inputField.type === "date";
}

export function isStringSummary(summaryField: SummaryField): summaryField is StringSummaryField {
  if (summaryField.items && summaryField.items.length > 0) {
    return (typeof summaryField.items[0].body === "string");
  }
  return false;
}

export function isStringSelectField(selectField: SelectField): selectField is StringSelectField {
  if (selectField.options && selectField.options.length > 0) {
    return (typeof selectField.options[0].value === "string");
  }
  return false;
}

export function isNumberSelectField(selectField: SelectField): selectField is NumberSelectField {
  if (selectField.options && selectField.options.length > 0) {
    return (typeof selectField.options[0].value === "number");
  }
  return false;
}

export function isDateSelectField(selectField: SelectField): selectField is DateSelectField {
  if (selectField.options && selectField.options.length > 0) {
    return dayjs(selectField.options[0].value.toString()).isValid();
  }
  return false;
}

export function isStringRadioSelectField(radioSelectField: RadioSelectField): radioSelectField is RadioSelectField {
  if (radioSelectField.options && radioSelectField.options.length > 0) {
    return (typeof radioSelectField.options[0].value === "string");
  }
  return false;
}

export function isStringCheckboxGroupField(
  checkboxGroupField: CheckboxGroupField,
): checkboxGroupField is CheckboxGroupField {
  if (checkboxGroupField.options && checkboxGroupField.options.length > 0) {
    return (typeof checkboxGroupField.options[0].value === "string");
  }
  return false;
}

export function isStringStepCounterSelectField(
  stepCounterSelectField: StepCounterSelectField,
): stepCounterSelectField is StepCounterSelectField {
  if (stepCounterSelectField.options && stepCounterSelectField.options.length > 0) {
    return (typeof stepCounterSelectField.options[0].value === "string");
  }
  return false;
}
